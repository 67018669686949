import './bootstrap';


if(document.title === 'Blank Notarissen - Contact'){
  var map = L.map('map').setView([52.37993410818806, 4.627562075707968], 14);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
  }).addTo(map);

  var marker = L.marker([52.37993410818806, 4.627562075707968]).addTo(map); 
}


window.addEventListener("click", function(event) {       
        
  var upClass = 'toggle-up';
  var downClass = 'toggle-down';
  var dropdownMenuArrow = document.querySelector('.dropdown-menu-arrow');
  var dropdownMenu = document.querySelector('#navbarDropdown');
 
  if(event.target.id === dropdownMenu.id || event.target.id === dropdownMenuArrow.id){    
      if (~dropdownMenuArrow.className.indexOf(downClass)) {                             
          dropdownMenuArrow.className = dropdownMenuArrow.className.replace(downClass, upClass);
      }else if(~dropdownMenuArrow.className.indexOf(upClass)){                         
          dropdownMenuArrow.className = dropdownMenuArrow.className.replace(upClass, downClass);
      }            
  }else{  
      if (~dropdownMenuArrow.className.indexOf(upClass)) {                
          dropdownMenuArrow.className = dropdownMenuArrow.className.replace(upClass, downClass);
      }                
  }     
});

// $("#dropdown-nav-item").click(toggle);

// function toggle() {
//   console.log("test");
//   var upClass = 'toggle-up';
//   var downClass = 'toggle-down';
//   var dropdownArrow = document.querySelector('.dropdown-menu-arrow');
  
//   if (~dropdownArrow.className.indexOf(downClass)) {
//     dropdownArrow.className = dropdownArrow.className.replace(downClass, upClass);
//   } else {
//     dropdownArrow.className = dropdownArrow.className.replace(upClass, downClass);
//   }  
// };


const listItems = document.querySelectorAll('.service-list-item');

listItems.forEach(function(item){  
  item.addEventListener("click", function(event){     
  
    var listItemsTextWrapperList = document.querySelectorAll('.service-list-item-text-wrapper');          
    const activeClass = 'service-list-item-active';
    const activeArrowClass = 'index-service-section-arrow-active';
    const activeTextBlockClass = 'text-active';        
    
    // scroll screen to textblock on mobile devices
    if (window.innerWidth < 992) {            
        const target = document.getElementById("service-text-col");
        const offsetTop = target.offsetTop -350;
        
        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }
  
    //remove all active classes for list items and arrows
      listItems.forEach(item => {
        
        item.classList.remove(activeClass);            
        var arrowElement = item.parentNode.children[1];           
        arrowElement.classList.remove(activeArrowClass, 'fade-in');            
    });
  
    //remove all active classes for textblocks
      listItemsTextWrapperList.forEach(textBlock => {
        textBlock.classList.remove(activeTextBlockClass, 'fade-in')
    })
  
    //set active classes for clicked list item and corresponding arrow and textblock
    var clickedListItem = event.currentTarget;
    clickedListItem.classList.add(activeClass);
    clickedListItem.parentNode.children[1].classList.add(activeArrowClass, 'fade-in');
  
    var clickedId = clickedListItem.getAttribute("href");       
    document.querySelector(clickedId).classList.add(activeTextBlockClass, 'fade-in');       
  
  });

});

